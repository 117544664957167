@font-face {
  font-family: 'Jost';
  src:
          local('Jost-Regular'),
          url('./Jost-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src:
          local('Jost-Medium'),
          url('./Jost-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src:
          local('Jost-Bold'),
          url('./Jost-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {

  font-family: 'Jost';
  src:
          local('Jost-ExtraBold'),
          url('./Jost-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}