.termsOfUse {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 688px;
  width: 100%;
  padding: 64px 16px;
  &Title {
    margin: 64px 0;
  }
  &TitleSecondary {

  }
  &TitleThird {

  }
  &Text {
    &tUppercase {
      text-transform: uppercase;
    }
  }
  &Container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
  &Block {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &BlockSecondary {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 16px;
  }
  a {
    text-decoration: none;
    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}