:root {
  --font-family: 'Jost', serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
body {
  background: linear-gradient(180deg, #1F2734 0%, #1C1238 100%);
  min-height: 100vh;
}