.mainPage {
    margin: 0 auto;
    padding: 0 16px;
    max-width: 1200px;
    background: url("../../shared/assets/img/hero.png") right center /772px no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
        background: url("../../shared/assets/img/hero.png") right -100px center /772px no-repeat;
    }
    @media (max-width: 850px) {
        background: url("../../shared/assets/img/hero.png") right -200px center /772px no-repeat;
    }
    @media (max-width: 768px) {
        background: none;
        flex-direction: column;
        gap: 24px;
        padding: 60px 24px;
    }
    @media (max-width: 350px) {
        padding: 60px 12px;
    }
    .logo {
        height: 95px;
    }

    &HeroImage {
        display: none;
        @media (max-width: 768px) {
            display: block;
            width: 400px;
        }
        @media (max-width: 425px) {
            width: 342px;
            height: 402px;
        }
        @media (max-width: 350px) {
            width: 300px;
            height: 350px;
        }
    }
    &RodWalkContainer {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    &TitleContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        p {
            @media (max-width: 768px) {
                text-align: center!important;
            }
        }

        @media (max-width: 768px) {
            align-items: center;
        }
    }
    &Title {
        background: radial-gradient(94.56% 111.08% at 5.47% 32.81%, #FFF 0%, #D4F1FD 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media (max-width: 768px) {
            font-size: 64px!important;
            line-height: 64px!important;
        }
        @media (max-width: 350px) {
            font-size: 56px!important;
            line-height: 56px!important;
        }
    }
    &Links {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @media (max-width: 7986px) {
            align-items: center;
        }

        a {
            text-decoration: none;
            @media (max-width: 768px) {
                p {
                    text-align: center!important;
                }

            }
            &:hover {
                p {
                    text-decoration: underline;
                }
            }
        }
    }
}