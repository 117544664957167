.apps {
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 163px);
    grid-template-rows: repeat(2, auto);
  }
  @media (max-width: 350px) {
    grid-template-columns: repeat(2, 1fr);
  }
  &Item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
    border-radius: 12px;
    &:first-child, &:nth-child(2) {
      background: #70F795;
    }
    &:last-child, &:nth-child(3) {
      background: rgba(112, 247, 149, 0.50);

      p {
        color: white;
      }
    }
      text-underline: none;
      text-decoration: none;
    @media (max-width: 768px) {
      padding: 12px 12px  ;
      &:nth-child(2) {
        order: 3;
      }
      &:nth-child(3) {
        order: 1;
      }
      &:nth-child(4) {
        order: 4;
      }
      p {
        font-size: 14px!important;
        line-height: 24px!important;
      }
    }
  }
}