.appText {
  font-family: var(--font-family);
  word-break: break-word;
}

.white {
  color: #FFFFFF;
}

.black {
  color: #1F2734;
}

.grey {
  color: #989BA0;
}

.blue {
  color: #3286EA;
}
