.supportPage {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 850px;
  width: 100%;
  padding: 64px 16px;
  &Title {
    margin: 64px 0 32px;
  }
  &Email {
    &:hover {
      text-decoration: underline;
    }
  }

  .logo {
    width: 100%;
    max-width: 190px;
  }
}
